import React from "react"
import Container from "./Container"
import Seo from "./seo"
import "twin.macro"

interface SimplePageProps {
  title: string
  html: string
}

const SimplePage: React.FC<SimplePageProps> = ({ title, html }) => {
  return (
    <>
      <Seo title={title} />
      <Container>
        <div tw="prose" dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </>
  )
}

export default SimplePage
