import React from "react"
import "twin.macro"
import { graphql, PageProps } from "gatsby"
import { PrivacyQuery } from "../../graphql-types"
import SimplePage from "../components/SimplePage"

const PrivacyPage: React.FC<PageProps<PrivacyQuery>> = ({ data }) => {
  const title = data.file?.childMarkdownRemark?.frontmatter?.title
  const html = data.file?.childMarkdownRemark?.html

  if (!title || !html) {
    return <></>
  }

  return <SimplePage title={title} html={html} />
}

export default PrivacyPage

export const pageQuery = graphql`
  query Privacy {
    file(sourceInstanceName: { eq: "pages" }, name: { eq: "privacy" }) {
      childMarkdownRemark {
        html
        frontmatter {
          title
        }
      }
    }
  }
`
